
import Vue from 'vue';
import Component from 'vue-class-component';
import authenticator from '@/modules/Auth/services/authenticator';
import { RETURN_ROUTE_LOCAL_STORAGE_KEY } from '@/modules/System/constants';
import localStorageManager from '@/utils/local-storage-manager';
import dateManager from '@/utils/time';
import API from '@/api/rest';

@Component
export default class Login extends Vue {
    private loader = !!localStorageManager.getStorage(RETURN_ROUTE_LOCAL_STORAGE_KEY);

    login() {
        authenticator.startLogin();
    }

    loaderCheck(count: number = 0) {
        setTimeout(() => {
            try {
                const returnRouteObject = JSON
                    .parse(localStorageManager.getStorage(RETURN_ROUTE_LOCAL_STORAGE_KEY));

                if (returnRouteObject) {
                    if (returnRouteObject.expirationDate && dateManager
                        .isBefore(dateManager
                            .getCurrentDateTime(), returnRouteObject.expirationDate)) {
                        if (count === 10) {
                            this.logError(new Error(JSON.stringify({ stopOnCount: count, message: '10 iterations and not stopping' })), count);
                        }
                        this.loaderCheck(count + 1);
                    } else {
                        this.logError(new Error(JSON.stringify({ stopOnCount: count })), count);
                        this.stopLoader();
                    }
                }
            } catch (e) {
                this.logError(new Error(JSON.stringify({ message: 'failed try catch', error: e instanceof Error ? e.message! : e })), 2);
                this.stopLoader();
            }
        }, 1000);
    }

    stopLoader() {
        this.loader = false;
        localStorageManager.clearStoreState(RETURN_ROUTE_LOCAL_STORAGE_KEY);
    }

    logError(e: Error, count: number) {
        if (count > 1) {
            try {
                API.registerError().registerFailure(e);
            // eslint-disable-next-line no-empty
            } catch {}
        }
    }

    created() {
        this.loaderCheck();
    }
}
